import styled from "styled-components";
import LogoSVG from "./logo.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_DASHBOARD } from "../../constants";

const Button = styled.button`
  padding: 6px;
  padding-left: 14px;
  padding-right: 17px;
  margin-bottom: 6px;
  background: transparent;
  border-width: 0;
  height: 36px;
  &:hover {
    cursor: pointer;
  }
`;

export default function Logo() {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(ROUTE_DASHBOARD)}>
      <LogoSVG />
    </Button>
  );
}
