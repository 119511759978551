import { FormControl, FormLabel, Input, InputProps } from "@mui/joy";

interface InputFieldProps extends InputProps {
  label?: string;
  innerRef?: React.Ref<HTMLInputElement>;
}

export const InputField = (props: InputFieldProps) => (
  <FormControl size={props.size || "sm"}>
    {props.label && (
      <FormLabel required={props.required}>{props.label}</FormLabel>
    )}
    <Input
      variant="outlined"
      type="text"
      autoComplete="off"
      ref={props.innerRef}
      {...props}
    />
  </FormControl>
);
