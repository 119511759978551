import { CSSProperties } from "react";

const logoStyle: CSSProperties = {
  width: "auto",
  height: 24,
  fill: "#4B35D2",
};

const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="c0137"
      viewBox="0 0 134 24"
      style={logoStyle}
    >
      <path
        d="M2.792 18.503c.881 1.324 2.415 2.099 3.874 2.099 1.434 0 2.39-.7 2.39-1.724 0-1-.73-1.7-2.49-2.3l-1.51-.524C2.19 15.055.656 13.406.656 10.982c0-3.349 2.566-5.323 6.037-5.323 2.189 0 4.1.75 5.635 2.45l-2.415 2.473C9.057 9.557 7.95 9.057 6.717 9.057c-1.233 0-2.39.525-2.39 1.574 0 1.075.88 1.55 2.768 2.224l1.433.525c2.617.95 4.252 2.599 4.252 5.272-.025 3.174-2.566 5.347-6.264 5.347-2.843 0-5.232-1.224-6.515-3.173l2.791-2.323zM18.866 9.407h-4.754V5.959h13.232v3.448h-4.805V23.7h-3.673V9.407zM30.11 5.959h6.843c3.346 0 5.485 2.298 5.485 5.247 0 2.374-1.309 3.873-3.674 4.623l5.467 7.87H40.1l-5.165-7.47H33.76v7.47h-3.648V5.96zm6.416 7.171c1.358 0 2.29-.724 2.29-1.924 0-1.224-.931-1.924-2.29-1.924h-2.768v3.848h2.768zM67.097 9.407h-4.755V5.959h13.232v3.448h-4.805V23.7h-3.672V9.407zM85.41 24c-4.276 0-7.27-2.874-7.27-7.297V5.96h3.648v10.795c0 2.349 1.459 3.748 3.622 3.748s3.623-1.4 3.623-3.748V5.959h3.647v10.744c0 4.423-3.043 7.297-7.27 7.297zM99.852 12.156V23.7h-3.647V5.959h3.32l5.811 7.896 5.811-7.896h3.321V23.7h-3.648V12.156l-5.484 7.471-5.484-7.471zM130.856 23.7l-9.157-11.569v11.57h-3.647V5.959h3.144l9.157 11.569V5.958H134v17.743h-3.144zM55.517 5.959h-3.245L44.574 23.7H63.24L55.517 5.959zm-5.78 14.476l4.145-9.729 4.143 9.729h-8.287zM59.553 0H48.464v3.265h11.089V0z"
        className="c0138"
        data-fill-white="false"
      ></path>
    </svg>
  );
};

export default LogoIcon;
