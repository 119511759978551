import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import AccountService from "../services/account/account.service";

import Header from "../components/header/header.comp";
import Footer from "../components/footer";
import { useDashboardContext } from "../context/DashboardContext";
import { useEffect } from "react";

export const layoutLoader = async () => {
  let data: User = await AccountService.getMe();
  return data;
};

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;
export default function LayoutPage() {
  const userData = useLoaderData() as User;
  const navigate = useNavigate();
  const { allDashboards, loading, activeDashboard } = useDashboardContext();

  const location = useLocation();
  //regarder react-router-dom-6 pour avoir la location et filtrer le footer depuis.

  useEffect(() => {
    if (!loading && location.pathname === "/") {
      // Redirect to the dashboard in local storage
      if (activeDashboard) return navigate(`/${activeDashboard.id}`);

      // else, redirect to the first dashboard in the list

      const firstDashboard = allDashboards.length > 0 ? allDashboards[0] : null;
      if (firstDashboard) {
        navigate(`/${firstDashboard.id}`);
      }
    }
  }, [loading, allDashboards, navigate]);
  return (
    <LayoutContainer>
      <Header user={userData} />
      <MainContainer>
        <Outlet />
      </MainContainer>
      <Footer />
    </LayoutContainer>
  );
}
