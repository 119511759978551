import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AnalyticsService from "../../services/analytics/analytics.service";
import { Dashboard } from "types/dashboards";
import { useDashboardContext } from "../../context/DashboardContext";
import NotFoundIcon from "../../assets/not-found.png";
import PageNotFound from "../errors/pagenotfound.page";
import { toast } from "sonner";

type IframesDict = {
  [key: string]: { url: string };
};

const deepCopy = (x: any) => JSON.parse(JSON.stringify(x));

export default function DashboardPage() {
  const { allDashboards, loading, activeDashboard, setActiveDashboard } =
    useDashboardContext();

  const { id: activeDashboardId } = useParams();

  const [iframesUrl, setIframesUrl] = useState<IframesDict>({});
  const [noFound, setNoFound] = useState(false);
  const updateIframeUrl = (dashboard: Dashboard, url: string) => {
    url = url?.replace("host.docker.internal", "localhost");
    setIframesUrl((obj) => {
      const copy = deepCopy(obj) as IframesDict;
      copy[dashboard.id] = { url };
      return copy;
    });
  };

  useEffect(() => {
    const fetchIframe = async () => {
      if (!loading) {
        const selectedDashboard = allDashboards.find(
          (dashboard) => dashboard.id === activeDashboardId
        );
        if (!selectedDashboard) {
          setActiveDashboard(null);
          setNoFound(true);
        } else {
          if (iframesUrl[selectedDashboard.id]) {
            return;
          }

          try {
            const embedResult = await AnalyticsService.getEmbed(
              selectedDashboard.id
            );

            if (embedResult) {
              updateIframeUrl(selectedDashboard, embedResult);
            }

            setActiveDashboard(selectedDashboard);
          } catch (error) {
            toast.error(`Error while fetching iframe `);
            throw new Error("Error while fetching iframe");
          }
        }
      }
    };
    if (activeDashboard !== null) {
      fetchIframe();
    }
  }, [activeDashboard, loading]);

  if (loading) {
    return (
      <Center>
        <h2>Loading...</h2>
      </Center>
    );
  }

  // should only trigger when the user has 0 dashboards (not when a link is invalid)
  if (!allDashboards?.length || allDashboards.length < 1) {
    return (
      <Center>
        <h2>You currently do not have access to any dashboard</h2>
      </Center>
    );
  }
  // Show error page if wrong dashboard id
  if (noFound) {
    return (
      <PageNotFound
        description="This dashboard doesn't exist"
        title="This dashboard doesn't exist"
        icon={NotFoundIcon}
      />
    );
  }

  const renderIframe = (dashboardId: string) => {
    return (
      <iframe
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          position: "absolute",
        }}
        src={iframesUrl[dashboardId].url}
      />
    );
  };

  return (
    <DashboardContainer>
      {activeDashboard && activeDashboard.id && (
        <div key={activeDashboard.id}>
          {iframesUrl[activeDashboard.id] &&
            iframesUrl[activeDashboard.id].url &&
            renderIframe(activeDashboard.id)}
        </div>
      )}
    </DashboardContainer>
  );
}

const DashboardContainer = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const Center = styled.div`
  width: "100%";
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
