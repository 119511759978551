import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FC,
  ReactElement,
} from "react";
import AnalyticsService from "../services/analytics/analytics.service";
import { GetDashboardDTO } from "../services/analytics/dto/analytics.dto";
import { Dashboard } from "../types/dashboards";
import { toast } from "sonner";

export async function dashboardLoader(): Promise<GetDashboardDTO> {
  let dashboardData = await AnalyticsService.getDashboards();
  return dashboardData;
}

interface DashboardContextT {
  allDashboards: Dashboard[];
  activeDashboard: Nullable<Dashboard>;
  setActiveDashboard: Dispatch<SetStateAction<Nullable<Dashboard>>>;
  storeLocallyActiveDashboard: (dashboard: Dashboard) => void;
  loading: boolean;
}

const DashboardContext = createContext<DashboardContextT>({
  allDashboards: [],
  activeDashboard: {} as Dashboard,
  setActiveDashboard: () => {},
  storeLocallyActiveDashboard: () => {},
  loading: false,
});

export const DashboardProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dashboardList, setDashboardList] = useState<Dashboard[]>([]);

  const [loading, setLoading] = useState(true);

  const dashboardFromStorage = localStorage.getItem("activeDashboard");

  const dashboardParsedFromStorage = dashboardFromStorage
    ? JSON.parse(dashboardFromStorage)
    : null;
  const [activeDashboard, setActiveDashboard] = useState<Nullable<Dashboard>>(
    dashboardParsedFromStorage
  );

  const storeLocallyActiveDashboard = (dashboard: Dashboard) => {
    localStorage.setItem("activeDashboard", JSON.stringify(dashboard));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dashboardsData = await AnalyticsService.getDashboards();
        const dashboardList = dashboardsData.dashboards;

        setDashboardList(dashboardList);
        if (activeDashboard) {
          setLoading(false);
          return;
        }

        if (dashboardList.length > 0) {
          setActiveDashboard(dashboardList[0]);
          storeLocallyActiveDashboard(dashboardList[0]);
        }
        setLoading(false);
      } catch (error) {
        toast.error("Error fetching dashboard list");
        throw new Error("Error while fetching the dashboard list");
      }
    };

    fetchData();
  }, []);
  return (
    <DashboardContext.Provider
      value={{
        allDashboards: dashboardList,
        loading,
        activeDashboard,
        setActiveDashboard,
        storeLocallyActiveDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
