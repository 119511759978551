import { Menu } from "@mui/joy";
import styled from "styled-components";

export const NavbarEditorMenu = styled(Menu)`
  z-index: 100001 !important;
  border: 1px solid lightgrey;
  MuiInput-root {
    background-color: transparent;
  }

  .Mui-focusVisible {
    outline: none !important;
  }

  .Mui-focused::before {
    box-shadow: none;
  }
`;

export const CustomNoFound = styled.div`
  text-align: center;
  padding: 10px;
`;

export const CustomMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #f0f4f8;
  }
`;
