export const ANALYTICS_API_URL: string = import.meta.env.VITE_ANALYTICS_API_URL;
export const ANALYTICS_UI_URL: string = import.meta.env.VITE_ANALYTICS_UI_URL;
export const ACCOUNT_API_URL: string = import.meta.env.VITE_ACCOUNT_API_URL;
export const TRACE_UI_URL: string = import.meta.env.VITE_TRACE_UI_URL;
export const ACCOUNT_UI_URL: string = import.meta.env.VITE_ACCOUNT_UI_URL;
export const CLIENT_ID: string = import.meta.env.VITE_CLIENT_ID;
export const VITE_ENV: string = import.meta.env.VITE_ENV;

export const ROUTE_DASHBOARD: string = '/';
export const ENV: string = import.meta.env.VITE_ENV;
