import styled from "styled-components";
import iconOops from "./oops.png";

const Root = styled.div`
  text-align: center;
  margin: 24px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

const Button = styled.button`
  height: 32px;
  width: 125px;
  background: #5246f7;
  font-size: 11px;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  font-family: "EuclidCircularB";
  font-weight: bold;
  border-width: 0;
  letter-spacing: 0.7px;

  &:hover {
    cursor: pointer;
  }
`;

interface OopsInterface {
  onHomeClick: any;
  label: string;
  description: string;
  icon?: string;
}

export default function Oops({
  onHomeClick,
  label,
  description,
  icon = iconOops,
}: OopsInterface) {
  return (
    <Root>
      <img
        alt=""
        src={icon}
        style={{ margin: "auto", width: 350, marginBottom: 12 }}
      />
      <p>{description}</p>
      <ButtonDiv>
        <Button onClick={onHomeClick}>{label}</Button>
      </ButtonDiv>
    </Root>
  );
}
