import { GetDashboardDTO } from './dto/analytics.dto';
import { Dashboard } from 'types/dashboards';
import { ANALYTICS_API_URL } from '../../constants';
import { Service } from '../service';

let instance: AnalyticServiceClass;

class AnalyticServiceClass extends Service {
	constructor() {
		if (instance) {
			console.log('singleton pattern, will not create the same instance twice');

			return instance;
		}

		super(ANALYTICS_API_URL);
		instance = this;
	}

	async getDashboards(): Promise<GetDashboardDTO> {
		const allDashboard = (await this.get('/dashboards')) as Dashboard[];

		if (allDashboard.length == 0) {
			return {
				dashboards: [],
				embed: undefined,
			};
		}

		let firstEmbed = undefined;
		if (allDashboard.length > 0) {
			firstEmbed = await this.get(`/dashboards/${allDashboard[0].id}/embed`);
		}

		return {
			dashboards: allDashboard,
			embed: firstEmbed.link,
		};
	}

	async getEmbed(id: string): Promise<string> {
		const embed = await this.get(`/dashboards/${id}/embed`);
		return embed.link;
	}
}

const AnalyticsService = Object.freeze(new AnalyticServiceClass());

export default AnalyticsService;
