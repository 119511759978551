import { useRef, useState } from "react";
import { Button, MenuList, Stack, Typography } from "@mui/joy";
import { Popper, ClickAwayListener } from "@mui/base";

import { useTheme } from "styled-components";

import AccountSVG from "./account.svg";
import SignOutSVG from "./signout.svg";
import Logo from "../logo/logo.comp";
import { ACCOUNT_UI_URL, TRACE_UI_URL } from "../../constants";
import { logout } from "../../utils/logout";
import { ENV } from "../../constants";
import { useDashboardContext } from "../../context/DashboardContext";
import DashboardMenu from "./dashboardMenu";
import {
  HeaderSection,
  EnvironmentSpan,
  Spacer,
  AccountPicture,
  ProfilElement,
  ButtonIcon,
  ButtonLabel,
  HeaderWrapper,
  NavbarMenuSection,
} from "./header.style";
import UserDefaultAvatar from "../../assets/avatar.svg";

export enum EnvironmentName {
  Local = "local",
  Demo = "demo",
  Sandbox = "sandbox",
  Staging = "staging",
  Preprod = "preprod",
  Release = "release",
}

interface HeaderProps {
  user: User;
}
export default function Header({ user }: HeaderProps) {
  const theme = useTheme();

  const { loading } = useDashboardContext();
  const [openMenu, setOpenMenu] = useState(false);

  const [isAccountHovered, setAccountIsHovered] = useState(false);
  const [isSingOutHovered, setSignOutIsHovered] = useState(false);
  const buttonRef = useRef<HTMLImageElement>(null);
  const isEnvironmentDisplayed: boolean =
    ENV !== EnvironmentName.Release &&
    !!ENV &&
    Object.values(EnvironmentName).includes(ENV as any);

  const handleClose = () => {
    setOpenMenu(false);
  };

  return (
    <HeaderSection>
      <HeaderWrapper>
        <Logo />

        <NavbarMenuSection>
          <Typography level="title-lg" textColor={theme.color.grey[6]}>
            /
          </Typography>
          {!loading && <DashboardMenu />}
        </NavbarMenuSection>
        {isEnvironmentDisplayed && (
          <EnvironmentSpan>{ENV} environment</EnvironmentSpan>
        )}
        <Spacer />
        <Stack
          spacing={2}
          direction="row"
          sx={{ margin: "0 24px", fontWeight: 600 }}
        >
          {/* TODO : add this when Doc will be done <Button
            color="neutral"
            size="sm"
            variant="plain"
            sx={{ fontWeight: 500 }}
          >
            Docs
          </Button>

          <Box sx={{ width: "1px", borderRight: "1px solid lightgrey" }} /> */}
          <Button
            color="primary"
            size="sm"
            variant="plain"
            onClick={() => window.open(TRACE_UI_URL, "_blank")}
          >
            Trace
          </Button>
        </Stack>
        <AccountPicture
          src={
            user && user.avatar !== null && user.avatar !== ""
              ? user.avatar
              : UserDefaultAvatar
          }
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          ref={buttonRef}
        />
        <Popper
          open={openMenu}
          anchorEl={buttonRef.current}
          disablePortal
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 4],
              },
            },
          ]}
          placement="bottom-end"
        >
          <ClickAwayListener
            onClickAway={(event) => {
              if (event.target !== buttonRef.current) {
                handleClose();
              }
            }}
          >
            <MenuList variant="outlined" sx={{ boxShadow: "md" }}>
              <ProfilElement
                onClick={() => (location.href = ACCOUNT_UI_URL)}
                style={{
                  borderBottom: "1px solid #4b35d21a",
                }}
                onMouseEnter={() => setAccountIsHovered(true)}
                onMouseLeave={() => setAccountIsHovered(false)}
              >
                <ButtonIcon>
                  <AccountSVG isHovered={isAccountHovered} />
                </ButtonIcon>
                <ButtonLabel>Account</ButtonLabel>
              </ProfilElement>
              <ProfilElement
                onClick={() => logout()}
                onMouseEnter={() => setSignOutIsHovered(true)}
                onMouseLeave={() => setSignOutIsHovered(false)}
              >
                <ButtonIcon>
                  <SignOutSVG isHovered={isSingOutHovered} />
                </ButtonIcon>
                <ButtonLabel>Sign out</ButtonLabel>
              </ProfilElement>
            </MenuList>
          </ClickAwayListener>
        </Popper>
      </HeaderWrapper>
    </HeaderSection>
  );
}
