import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  gap: 24px;
  padding: 3px 24px;
  border-top: solid 1px #4b35d21a;
  box-shadow: 0px -3px 15px 0px #4b35d21a;
  font-size: 10px;
  color: ${(props) => props.theme.color.indigo[2]};

  & a {
    color: #4b35d2;
    text-decoration: none;

    &:hover {
      text-decoration: underli^ne;
    }
  }
`;

const Footer = () => {
  const copyrightText = `Stratumn © ${new Date().getFullYear()}. All rights reserved.`;
  const privacyPolicyLink = "/gdpr-privacy-policy";
  const privacyPolicyText = "GDPR privacy policy";
  return (
    <FooterContainer id="footer">
      <span>{copyrightText}</span>
      <span>
        <Link to={privacyPolicyLink}>{privacyPolicyText}</Link>
      </span>
    </FooterContainer>
  );
};

export default Footer;
