import styled, { css } from "styled-components";

const MAIN_HEADER_HEIGHT = "70px";
export const HeaderSection = styled.header`
  height: ${MAIN_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 14px;
  box-shadow: 0px 3px 15px 0px #4b35d21a;
  border-bottom: solid 1px #4b35d21a;
  background-color: white;
  z-index: 100;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
  padding-right: 24px;
  align-items: center;
`;

export const NavbarSection = styled.nav`
  display: flex;
  opacity: 1;
  transition: opacity 250ms linear 250ms;
  justify-content: space-between;
`;

export const NavbarMenuSection = styled(NavbarSection)`
  align-items: center;
  gap: 20px;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const AccountPicture = styled("img")`
  border-radius: 50px;
  background-size: cover;
  width: 40px;
  cursor: pointer;
  height: 40px;
`;

export const ProfilElement = styled.li`
  appearance: none;
  flex-grow: 1;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  outline: none;
  color: inherit;
  &:focus:not(:hover) {
    color: #5246f7;
  }
  &:hover {
    background-color: #5246f7;
    color: #ffffff;
    bordertopcolor: #5246f7;
    & + * {
      bordertopcolor: #5246f7;
    }
  }
`;

export const ButtonIcon = styled.span`
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  & > svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const ButtonLabel = styled.span`
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
`;

export const EnvironmentSpan = styled.span`
  background: linear-gradient(to right, #4b35d2 0%, #5246f7 100%);
  border-radius: 1rem;
  width: max-content;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 20px;
  padding-inline: 12px;
  margin-left: 12px;
  user-select: none;
`;
