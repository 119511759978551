import { ACCOUNT_API_URL } from "../../constants";
import { GraphQLService } from "../../services/graphql.service";
import { gql } from "@apollo/client";

let instance: AccountServiceClass;

class AccountServiceClass extends GraphQLService {
    constructor() {
        if (instance) {
            console.log(
                "singleton pattern, will not create the same instance twice"
            );

            return instance;
        }

        super(ACCOUNT_API_URL + "/graphql");
        instance = this;
    }

    async getMe() {
        let data = await this.query({
            query: gql`
                query getUser {
                    me {
                        avatar
                        email
                        firstName
                        lastName
                    }
                }
            `,
            variable: {}
        })

        return data.me
    }
}

const AccountService = Object.freeze(new AccountServiceClass());

export default AccountService;
