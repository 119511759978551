import { useEffect, useMemo, useRef, useState } from "react";
import { ChevronsUpDown, Search } from "lucide-react";
import { MenuItem, Dropdown, MenuButton, ListDivider } from "@mui/joy";
import {
  NavbarEditorMenu,
  CustomMenuItem,
  CustomNoFound,
} from "./dashboardMenu.style";
import { InputField } from "./InputField";

import { useNavigate } from "react-router-dom";
import { useDashboardContext } from "../../../context/DashboardContext";
import { useTheme } from "styled-components";

const DashboardMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    activeDashboard,
    setActiveDashboard,
    allDashboards,
    storeLocallyActiveDashboard,
  } = useDashboardContext();
  const [searchText, setSearchText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const displayedOptions = useMemo(
    () =>
      allDashboards.filter((dashboard) => {
        return dashboard?.name
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
      }),
    [searchText]
  );

  const onDashboardChange = (newValue: string) => {
    setSearchText("");

    const selectedDashboard = allDashboards.find(
      (dashboard) => dashboard.id === newValue
    );
    if (selectedDashboard) {
      setActiveDashboard(selectedDashboard);
      storeLocallyActiveDashboard(selectedDashboard);
      navigate(`/${newValue}`);
    }
  };

  const stopEventPropagation = (evt: React.KeyboardEvent<HTMLElement>) => {
    evt.stopPropagation();
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const dashboardIdFromUrl = location.pathname.split("/").pop();

    if (dashboardIdFromUrl && dashboardIdFromUrl !== activeDashboard?.id) {
      const selectedDashboard = allDashboards.find(
        (dashboard) => dashboard.id === dashboardIdFromUrl
      );

      setActiveDashboard(selectedDashboard ?? null);
      if (selectedDashboard) storeLocallyActiveDashboard(selectedDashboard);
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isMenuOpen]);
  return (
    <Dropdown
      onOpenChange={() => {
        setSearchText("");
        setIsMenuOpen(!isMenuOpen);
      }}
    >
      <MenuButton
        size="sm"
        endDecorator={<ChevronsUpDown color={theme.color.grey[6]} size={14} />}
        variant="plain"
        sx={{ gap: "10px", fontWeight: 600 }}
      >
        {activeDashboard?.name}
      </MenuButton>
      <NavbarEditorMenu>
        <MenuItem
          onKeyDown={stopEventPropagation}
          sx={{
            padding: 0,
            '&:not(.Mui-selected, [aria-selected="true"]):hover': {
              bgcolor: "transparent",
            },
          }}
        >
          <InputField
            id="input-field"
            variant="plain"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            startDecorator={
              <Search size={20} style={{ marginRight: "10px" }} />
            }
            placeholder="Search dashboard..."
            slotProps={{ input: { ref: inputRef } }}
            sx={{ padding: "15px 10px" }}
          />
        </MenuItem>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          <ListDivider sx={{ borderBottom: "1px solid lightgray" }} />
          {displayedOptions.length === 0 && (
            <CustomNoFound>No dashboard found</CustomNoFound>
          )}
          {displayedOptions.map((dashboard) => (
            <CustomMenuItem
              key={dashboard.id}
              onClick={() => onDashboardChange(dashboard.id)}
            >
              {dashboard.name}
            </CustomMenuItem>
          ))}
        </div>
      </NavbarEditorMenu>
    </Dropdown>
  );
};

export default DashboardMenu;
