import { useEffect } from "react";
import Oops from "../../components/oops/oops.comp";
import { ROUTE_DASHBOARD } from "../../constants";
import { useNavigate } from "react-router-dom";

interface PageNotFoundInterface {
  description?: string;
  label?: string;
  title?: string;
  icon?: string;
}

export default function PageNotFound({
  description = "Sorry, we can't find the page you're looking for.",
  label = "take me home",
  title = "Oops! Page not found - Trace",
  icon,
}: PageNotFoundInterface) {
  const navigate = useNavigate();
  const goTo = () => {
    navigate(ROUTE_DASHBOARD, { replace: true });
    window.location.reload();
  };
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Oops
      onHomeClick={goTo}
      description={description}
      label={label}
      icon={icon}
    />
  );
}
