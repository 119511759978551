import { ACCOUNT_UI_URL } from "../constants";

export class Service {
    URL: string;

    constructor(URL: string) {
        this.URL = URL;
    }

    async request(url: string, method: string, body = {}, headers = {}) {
        let req = await fetch(url, {
            method: method,
            credentials: "include",
            headers: {
                ...headers,
                "content-type": "application/json",
            },
            body: method != 'GET' ? JSON.stringify(body) : undefined,
        });

        if (req.status == 401) {
            console.log("you are not logged in");
            location.href = ACCOUNT_UI_URL;
        }

        return await req.json();
    }

    async get(url: string) {
        return await this.request(`${this.URL}${url}`, "GET");
    }

    async post(url: string, data: any) {
        return await this.request(`${this.URL}${url}`, "POST", data);
    }

    async put(url: string, data: any) {
        return await this.request(`${this.URL}${url}`, "PUT", data);
    }

    async delete(url: string) {
        return await this.request(`${this.URL}${url}`, "DELETE");
    }
}
