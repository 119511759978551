import { ThemeProvider } from "styled-components";
import { extendTheme, CssVarsProvider } from "@mui/joy/styles";

import PageNotFound from "./pages/errors/pagenotfound.page";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import LayoutPage, { layoutLoader } from "./layout/layout.page";
import DashboardPage from "./pages/dashboard/dashboard.page";
import GdprPolicy from "./pages/gdprPolicy";
import * as Sentry from "@sentry/react";
import { DashboardProvider } from "./context/DashboardContext";
import theme from "./styles/theme";
import { Toaster } from "sonner";

const routerFactory = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = routerFactory(
  createRoutesFromElements(
    <Route
      path="/"
      element={<LayoutPage />}
      loader={layoutLoader}
      errorElement={
        <PageNotFound
          description="An error has occured"
          title="An error has occured"
        />
      }
    >
      <Route
        path="/:id"
        element={<DashboardPage />}
        errorElement={
          <PageNotFound
            description="An error has occured"
            title="An error has occured"
          />
        }
      />
      <Route
        path="gdpr-privacy-policy"
        element={<GdprPolicy />}
        errorElement={
          <PageNotFound
            description="An error has occured"
            title="An error has occured"
          />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

const muiTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: "white",
        },
        primary: {
          "50": "#edf1ff",
          "100": "#dee4ff",
          "200": "#c4ccff",
          "300": "#a1abff",
          "400": "#7b7ffe",
          "500": "#635cf8",
          "600": "#533eed",
          "700": "#4b35d2",
          "800": "#3a2aa9",
          "900": "#332a85",
        },
      },
    },
  },
  components: {
    JoyMenuItem: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "10px",
          cursor: "pointer",
        },
      },
    },
  },
});

const toastOptions = {
  style: { border: "none" },
};
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssVarsProvider theme={muiTheme}>
        <DashboardProvider>
          <>
            <RouterProvider router={router} />
            <Toaster
              richColors
              position="bottom-left"
              closeButton
              expand
              toastOptions={toastOptions}
            />
          </>
        </DashboardProvider>
      </CssVarsProvider>
    </ThemeProvider>
  );
}

export default App;
