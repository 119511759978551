import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { ACCOUNT_UI_URL } from "../constants";
import { ANALYTICS_UI_URL } from "../constants";
import { CLIENT_ID } from "../constants";

export class GraphQLService {
  URL: string = "";
  //@ts-ignore
  client: ApolloClient;

  constructor(URL: string) {
    this.URL = URL;

    const link = createHttpLink({
      uri: URL,
      credentials: "include",
    });

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });
  }

  async query(data: any) {
    try {
      let req = await this.client.query(data);
      if (req.status == 401) {
        console.log("you are not logged in");
      }
      console.log(req);

      return req.data;
    } catch (e) {
      //@ts-ignore
      if (e?.networkError?.result?.errors[0].status == 401) {
        location.href = `${ACCOUNT_UI_URL}/login?clientId=${CLIENT_ID}&redirectUri=${window.location.href}`;
      } else {
        console.log("error while querying", e);
      }
    }
  }
}
