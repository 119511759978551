import styled from "styled-components";
import { gdprPolicyContent } from "./gdprPolicyContent";

const ScrollWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`;

const ScrollBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
`;

const Container = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 70px 20px;
  font-size: 12px;
  color: #2e058d;

  & section {
    margin: 40px 0;
  }

  & h1 {
    font-size: 26px;
    font-weight: 600;
  }

  & h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  & span {
    font-size: 12px;
  }

  & li {
    position: relative;
    padding-left: 16px;
    display: flex;
    margin: 14px 0px;

    & span::before {
      position: absolute;
      font-size: 10px;
      content: "\\25ba";
      color: #2e058d;
      margin-left: -16px;
      top: 2px;
    }

    & div {
      margin-left: 6px;
    }

    & li span::before {
      color: #2e058d;
      content: "\\25B7";
    }

    & a {
      color: #4b35d2;
      font-weight: 600;
      text-decoration: none;
      margin-left: 6px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  border: 1px solid #2e058d;

  & div {
    border: 1px solid #2e058d;
    padding: 6px;
  }
`;

const HowDataCollectedTableGrid = styled(Grid)`
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: auto auto auto;

  & div:nth-child(-n + 3) {
    font-size: 16px;
    font-weight: 600;
  }
`;

const LegalBasisGrid = styled(Grid)`
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: auto auto;

  & div:nth-child(-n + 2) {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const GdprPolicy = () => {
  const {
    preamble,
    identification,
    purposes,
    whatData,
    howDataCollectedTable,
    legalBasis,
    recipients,
    howLong,
    exerciseRights,
  } = gdprPolicyContent;

  return (
    <ScrollWrapper>
      <ScrollBlock>
        <Container>
          <h1>GDPR</h1>
          <section>
            <h3>Preamble</h3>
            <span>{preamble}</span>
          </section>
          <section>
            <h3>{identification.title}</h3>
            <div>
              <ul>
                {identification.bullets.map((bullet) => (
                  <li>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{purposes.title}</h3>
            <span>{purposes.intro}</span>
            <div>
              <ul>
                {purposes.bullets.map((bullet) => (
                  <li>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{whatData.title}</h3>
            <span>{whatData.intro}</span>
            <div>
              <ul>
                {whatData.bullets.map((bullet) => (
                  <li>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{howDataCollectedTable.title}</h3>
            <HowDataCollectedTableGrid>
              {howDataCollectedTable.items.map((item) => (
                <div
                  style={{
                    gridRowStart: item.rowStart,
                    gridRowEnd: item.rowEnd,
                    gridColumnStart: item.column,
                  }}
                >
                  <span>{item.text}</span>
                </div>
              ))}
            </HowDataCollectedTableGrid>
          </section>
          <section>
            <h3>{legalBasis.title}</h3>
            <LegalBasisGrid>
              {legalBasis.items.map((item) => (
                <div
                  style={{
                    gridRowStart: item.rowStart,
                    gridRowEnd: item.rowEnd,
                    gridColumnStart: item.column,
                  }}
                >
                  <span>{item.text}</span>
                </div>
              ))}
            </LegalBasisGrid>
          </section>
          <section>
            <h3>{recipients.title}</h3>
            <span>{recipients.intro}</span>
            <div>
              <ul>
                {recipients.bullets.map((bullet) => (
                  <li>
                    <span>{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section>
            <h3>{howLong.title}</h3>
            <ul>
              <li>
                <span>{howLong.bullets.title}</span>
              </li>
              <li>
                <span>
                  {howLong.bullets.insideBullet.title}
                  <ul>
                    {howLong.bullets.insideBullet.insideBullets.map((x) => (
                      <li>
                        <span>{x}</span>
                      </li>
                    ))}
                  </ul>
                </span>
              </li>
            </ul>
          </section>
          <section>
            <h3>{exerciseRights.title}</h3>
            <span>{exerciseRights.intro}</span>
            <div>
              <ul>
                <li>
                  <span>{exerciseRights.emailText}</span>
                  <a href={`mailto:${exerciseRights.email}`}>
                    {exerciseRights.email}
                  </a>
                </li>
                <li>
                  <span> {exerciseRights.postText}</span>
                  <div> {exerciseRights.postAddress}</div>
                </li>
              </ul>
              <span>{exerciseRights.outroText}</span>
            </div>
          </section>
        </Container>
      </ScrollBlock>
    </ScrollWrapper>
  );
};
export default GdprPolicy;
